<template>
	<div :id="Brokenline" style="min-width: 100%;height: 280px;"></div>
</template>

<script>
var echarts = require('echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
	export default {
		props: ['Brokenline', 'Brokenlinedata'],
		data() {
			return {}
		},
		created() {

		},
		mounted() {
			this.drawLine(this.Brokenlinedata)
		},
		methods: {
			drawLine(Brokenlinedata) {
				document.getElementById(this.Brokenline).setAttribute('_echarts_instance_', '')
				var option1 = {
					tooltip: {
						trigger: 'axis',
						formatter(params) {
							for (let x in params) {
								return params[x].axisValueLabel + "</br>" + "正确率:" + params[x].value + "%"
							}
						}
					},
					grid: {
						left: '3%',
						right: '3%',
						bottom: '15%',
						top: '10%'
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: Brokenlinedata.data1
					},
					yAxis: {
						type: 'value',
					},
					series: [{
						data: Brokenlinedata.data2,
						type: 'line',
						areaStyle: {},
						symbol:'none', //去掉折线图中的节点
						smooth: true  //true 为平滑曲线，false为直线
					}]
				}
				let dom = document.getElementById(this.Brokenline)
				var myChart = echarts.init(dom);
				// 绘制图表
				myChart.setOption(option1,true);
			}
		}
	}
</script>

<style scoped>
</style>
