<template>
	<!-- 学生学情界面 -->
	<div class="study_con_zuoye" id="container">
		<div class="study_con_top">
			<div class="study_con_top_left">
				<button @click="studybut=1;queryerrorCatalogBetweenTimeList()" class="basebtn" :class="[studybut=='1'?'studybutclass':'']">{{$store.state.teachlanguagedata.frequencywrong}}</button>
				<button @click="studybut=2;queryerrorCatalogBetweenTimeList()" class="basebtn" :class="[studybut=='2'?'studybutclass':'']">{{$store.state.teachlanguagedata.knowledge}}</button>
				<button @click="studybut=3;QueryErrorLabelBetweenTimeList('analyticMethod')" class="basebtn" :class="[studybut=='3'?'studybutclass':'']">{{$store.state.teachlanguagedata.problemSolvingProcess}}</button>
				<button @click="studybut=4;QueryErrorLabelBetweenTimeList('thinkModel')" class="basebtn" :class="[studybut=='4'?'studybutclass':'']">{{$store.state.teachlanguagedata.thinkingModel}}</button>
			</div>
			<div class="study_con_top_right">
				<span></span>
				<span>0 < {{$store.state.teachlanguagedata.correct}} ≤ 30%</span>
				<span></span>
				<span>30% < {{$store.state.teachlanguagedata.correct}} ≤ 60%</span>
				<span></span>
				<span>60 < {{$store.state.teachlanguagedata.correct}} ≤ 100%</span>
			</div>
		</div>
		<div class="study_con_con">
			<el-table :data="Reportdatas" style="width: 100%" row-key="id"
				:tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}"
				:header-cell-style="{background:'#F3F5F9',color:'#606266'}"
				:default-sort="{prop: 'right_rate', order: 'descending'}">
				<el-table-column type="index" :label="$store.state.teachlanguagedata.serialnumber">
				</el-table-column>
				<el-table-column v-if="studybut==1" :label="$store.state.teachlanguagedata.topicname">
					<template slot-scope="scope">
						<div class="timuclass" :class="subjectTitleId==scope.row.id?'subjectTitle1':'subjectTitle'"
							@click="subjectTitleClick(scope.row)" v-html="scope.row.content">
						</div>
					</template>
				</el-table-column>
				<el-table-column v-else :label="studybut==2?$store.state.teachlanguagedata.knowledge:studybut==3?$store.state.teachlanguagedata.problemSolvingProcess:$store.state.teachlanguagedata.thinkingModel">
					<template slot-scope="scope">
						<div>
							{{scope.row.name}}
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import {
		queryErrorCatalogBetweenTimeList
	} from '../../api/Explain.js'
	import {
		queryErrorLabelBetweenTimeList
	} from '../../api/UserStudy.js'
	export default {
		name: 'Study',
		props: ['Reportdata'],
		data() {
			return {
				studybut: 1,
				subjectTitleId: '',
				Reportdatas:[]
			}
		},
		watch:{
			Reportdata(data){
				this.queryerrorCatalogBetweenTimeList()
			}
		},
		created() {
		},
		mounted() {
		},
		methods: {
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("container");
					this.Mathqunatishow = true
				})
			},
			QueryErrorLabelBetweenTimeList(kind){
				let data=JSON.parse(localStorage.getItem("teachUserStudydata"))
				data.parameters.kind=kind
				queryErrorLabelBetweenTimeList(data).then(res=>{
					this.Reportdatas=res.data.result
				})
			},
			//学生学情高频错题的方法
			queryerrorCatalogBetweenTimeList(){
				if(this.studybut==1){
					this.Reportdatas=this.Reportdata
					this.Mathqunati()
					return
				}
				queryErrorCatalogBetweenTimeList(JSON.parse(localStorage.getItem("teachUserStudydata"))).then(res=>{
					this.Reportdatas=res.data.result
					this.Mathqunati()
				})
			},
			customColorMethod(percentage) {
				if (percentage < 30) {
					return '#4CAAFF';
				} else if (percentage < 60) {
					return ' #FF8B00';
				} else {
					return '#FF5630';
				}
			},
			filterData(data) {
				if (data) {
					var j = data.substring(0, data.indexOf('.')) * 1;
					var i = data.substring(data.indexOf('.') + 1, data.indexOf('.') + 2);
					if (i > 4) {
						j = j + 1
					}
					if (j > 0) {
						return j
					} else {
						return 0
					}
				} else {
					return 0
				}
			},
			sortByVesselName(obj1, obj2) {
				if (obj1.right_rate && obj2.right_rate) {
					return this.filterData(obj1.right_rate) -  this.filterData(obj2.right_rate);
				} else {}
			},
			subjectTitleClick(data) {
				if (this.subjectTitleId == data.id) {
					this.subjectTitleId = ''
				} else {
					this.subjectTitleId = data.id
				}
			},
		}
	}
</script>

<style lang="scss">
	.study_con_zuoye {
		width: 100%;

		.study_con_top {
			display: flex;
			justify-content: space-between;

			.study_con_top_left {
				button {
					height: 32px;
				}
			}

			.study_con_top_right {
				display: flex;
				align-items: center;

				span:nth-child(odd) {
					width: 11px;
					height: 12px;
					display: inline-block;
					margin: 0 10px;
				}

				span:nth-child(1) {
					background: #4CAAFF;
				}

				span:nth-child(3) {
					background: #FF8B00;
				}

				span:nth-child(5) {
					background: #FF5630;
				}
			}
		}

		.study_con_con {
			margin-top: 12px;
			max-height: 52vh;
			overflow: auto;

			.subjectTitle {
				margin: 0;
				white-space: nowrap;

				p:not(:first-child) {
					display: none;
				}
			}

			.subjectTitle img {
				max-width: 100px;
			}

			.subjectTitle1 img {
				max-width: 100%;
			}
		}
	}
</style>
