<template>
  <!-- 菜单总览zdx -->
  <div
    class="container"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="row clearfix  UserStudys displayFlex" id="clearfix">
      <div class="classSelect">
        <div class="selectTitle">年级选择：</div>
        <el-select
          v-model="gradeSelectId"
          clearable
          placeholder="请选择年级"
          @change="changeGradeSelectId"
        >
          <el-option
            v-for="item in gradeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="selectTitle">班级选择：</div>
        <el-select
          v-model="classSelectId"
          clearable
          placeholder="请选择班级"
          @change="changeClassSelectId"
        >
          <el-option
            v-for="item in classList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="selectTitle" style="font-size:20px">开始月份：</div>
        <div class="block">
          <el-date-picker
            v-model="startTime"
            type="month"
            placeholder="选择月份"
            @change="changeStartTime"
            :picker-options="pickerOptions"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
        <div class="selectTitle" style="font-size:20px">结束月份:</div>
        <div class="block">
          <el-date-picker
            v-model="endTime"
            type="month"
            placeholder="选择月份"
            @change="changeEndTime"
            :picker-options="pickerOptions"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
        <div class="btns">
          <el-button type="primary" @click="onFirm()">确认</el-button>
          <el-button @click="onReSet()">重置</el-button>
        </div>
      </div>

      <!-- <div class="row clearfix UserStudys"> -->
      <!-- <Nav_class v-if="1" ref="Nav_class" class="text_class"></Nav_class> -->
      <div
        class="UserStudys_con"
        style="margin: 15px;flex: 1 1 auto;overflow: auto;"
      >
        <div class="study_top_top">
          <span class="UserStudys_con_top_top base_border_text"
            >近期人工智能辅助行为预测</span
          >
        </div>
        <div class="onWarningTable">
          <template>
            <el-table :data="tableData" style="width: 100%" max-height="250">
              <el-table-column prop="date" label="日期" width="180">
              </el-table-column>
              <el-table-column prop="class" label="班级" width="180">
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="180">
              </el-table-column>
              <el-table-column prop="warn" label="问题预警">
                <template slot-scope="scope">
                  <el-button
                    plain
                    type="warning"
                    @click="openSinglePass(scope.row)"
                    >{{ scope.row.warn }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
        <div class="UserStudys_con_top">
          <div class="UserStudys_con_top_top base_border_text">
            总览
          </div>
          <div class="UserStudys_con_top_con">
            <Overview :Overviewdata="usersoce1"></Overview>
          </div>
        </div>
        <div class="study_top_top" v-if="pressureTestShow">
          <span class="UserStudys_con_top_top base_border_text">笔迹分析</span>
        </div>

        <div slot="title" v-if="pressureTestShow">
          <span style="font-weight: 600;font-size: 20px; padding:5px"
            >压力测试图表:</span
          >
        </div>
        <PressureTest
          v-if="pressureTestShow"
          :classStudent="classStudent"
          :endTime="endTime"
          :startTime="startTime"
        ></PressureTest>
      </div>
    </div>
    <el-dialog
      :title="singleDialogTitle"
      :visible.sync="singleDialog"
      width="80%"
    >
      <testEchartsSandiantu
        ref="testEchartsSandiantu"
        :singlePass="singlePass"
        style=" padding: 20px; display:flex;  justify-content:center
  align-content: center;"
      ></testEchartsSandiantu>
    </el-dialog>
  </div>
</template>

<script>
import testEchartsSandiantu from '../../components/Ceshi/testEcharts-sandiantu.vue';
import dayjs from 'dayjs';
import qs from 'qs';
import { getDotsTimeAndPressure } from '../../api/Correct';
import {
  getOverviewGradeList,
  getOverviewClassList,
  getSchoolMacList,
  classIdGetMacList,
} from '../../api/class';
import PressureTest from '../../components/pressureTest.vue';
import Nav_class from '../../components/Nav_class1';
import Overview from '../../components/report/Overview.vue';
import Report from '../../components/report/Report.vue';
import Report1 from '../../components/report/Report1.vue';
import Brokenline from '../../components/echarts/Brokenline.vue';
export default {
  name: 'Class',
  components: {
    PressureTest,
    Nav_class,
    Overview,
    Report,
    Brokenline,
    Report1,
    testEchartsSandiantu,
  },
  data() {
    return {
      //点击出现表格点击弹框
      singlePass: 0, // 每点击过
      singleDialogTitle: '',
      singleDialog: false,
      //警报数据
      tableData: [
        {
          id: 1,
          date: '2023-05-22',
          class: '四七班',
          name: '08',
          warn: '该同学作业耗时过长,请老师关注',
        },
        {
          id: 2,
          date: '2023-05-16',
          class: '二一班',
          name: '30',
          warn: '该同学作业书写压力值较大,请老师关注',
        },
        {
          id: 3,
          date: '2023-05-12',
          class: '二一班',
          name: '07',
          warn: '该同学作业耗时过长,请老师关注',
        },
        {
          id: 4,
          date: '2023-05-09',
          class: '二四班',
          name: '14',
          warn: '该同学作业耗时过长,请老师关注',
        },
        {
          id: 5,
          date: '2023-05-08',
          class: '五二班',
          name: '11',
          warn: '该同学作业书写压力值较大,请老师关注',
        },
        {
          id: 6,
          date: '2023-05-08',
          class: '五二班',
          name: '19',
          warn: '该同学作业耗时过长,请老师关注',
        },
      ],
      classStudent: [], //选择班级后得到1学生列表
      pressureTestShow: false, // 是否显示压力测试表
      loading: false,
      macList: [],
      startTime: '',
      endTime: '',
      //限制时间选择器只能选择当前时间之前的时间
      pickerOptions: {
        disabledDate(time) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return time.getTime() > Date.now(); //选当前时间之前的时间
          //return time.getTime() < Date.now()//选当前时间之后的时间
        },
      },
      classList: [], //获取的班级列表
      gradeList: [], //获取年级列表
      classSelectId: null, //下拉框选的班级id
      gradeSelectId: null, //下拉框选择的年级id

      value1: ['', ''],
      value1cha: [],
      UserStudybtn: 1, //选  班级学情 还是学生学情
      option: {
        data1: [],
        data2: [],
      },
      Reportdata: [],
      Reportdata1: [],
      usersoce1: [
        {
          name: '笔总数量（个）',
          value: 0,
          key: ['completedhomework', 'copy'],
          img: require('../../assets/img/iconuser.png'),
        },
        {
          name: '使用时长（时.分.秒）',
          value: 0,
          key: ['completedquestions', 'topic'],
          img: require('../../assets/img/iconuser.png'),
        },
        {
          name: '平均压力值',
          value: 0,
          key: ['numberofcompletedquestions', 'numberofpeople'],
          img: require('../../assets/img/iconuser.png'),
        },
      ],
    };
  },
  mounted() {
    //获取年级列表
    this.getGradeList();
  },
  methods: {
    //点击出现单个压力值
    openSinglePass(row) {
      this.singleDialog = true;
      this.singleDialogTitle = `学生：${row.name} ：详情`;
      //让压力组件知道这是单个警告的弹框要学生数据 顺便给个名字和id
      this.singlePass = row;
      //vue组件加载完之后在执行函数
      //vue组件加载完之后在执行函数
      this.$nextTick(() => {
        this.$refs.testEchartsSandiantu.singleEcharts();
      });
    },
    // 点击确认
    async onFirm() {
      let _this = this;
      // 1.判断时间是否选择(必填)
      if (!this.startTime) {
        this.$message({
          message: '请先选择起始月份',
          type: 'warning',
          duration: 3000,
        });
        this.endTime = '';
        return;
      }
      if (!this.endTime) {
        this.$message({
          message: '请先选择结束月份',
          type: 'warning',
          duration: 3000,
        });
        return;
      }
      this.loading = true;
      if (!this.gradeSelectId) {
        // 2-1.如果年级为空的 拿到所有值
        this.pressureTestShow = false;
        this.getSchoolMac();
      } else if (!this.classSelectId) {
        this.pressureTestShow = false;
        //2-2如果年级不为空  --班级为空  --2-2-1 先获取所有班级列表  2-2-2 拿各个班级id查底下的mac
        this.macList = [];
        for (let i = 0; i < this.classList.length; i++) {
          let res = await classIdGetMacList({
            id: this.classList[i].id,
            relationKind: 'student',
          });
          if (res.data.code == '0000') {
            res.data.result.list.map((itemMac, index11) => {
              this.macList.push(itemMac.penMac);
              if (index11 == res.data.result.list.length - 1) {
              }
            });
          }
          if (i == this.classList.length - 1) {
            //最后一次
            // console.log(_this.macList);
            this.usersoce1[0].value = this.macList.length;
            //2-3 mac 已经获取  用mac和时间获取压力和使用时间
            this.getTimeAndPressure();
          }
        }
      } else if (this.classSelectId) {
        this.pressureTestShow = false;
        //班级也选择了
        this.macList = [];
        //通过classid 获取学生mac列表
        let res = await classIdGetMacList({
          id: this.classSelectId,
          relationKind: 'student',
        });
        //将学生列表传到压力测试表
        this.classStudent = res.data.result.list;
        this.macList = res.data.result.list.map((itemMac) => {
          return itemMac.penMac;
        });
        this.usersoce1[0].value = this.macList.length;
        this.getTimeAndPressure();
        if (this.macList.length > 0) {
          this.pressureTestShow = true;
        }
      } else {
        alert('不应该走进这里的');
      }
    },
    //重置
    onReSet() {
      (this.endTime = ''),
        (this.startTime = ''),
        (this.classList = []),
        (this.gradeSelectId = null),
        (this.classSelectId = null),
        (this.usersoce1[0].value = 0);
      this.usersoce1[1].value = 0;
      this.usersoce1[2].value = 0;
    },
    //获取年级列表
    async getGradeList() {
      //表单提交  等会看看原理
      // let fd = new FormData();
      // fd.append('position', 1);
      let fd = {
        position: 1,
      };
      // qs.stringify(fd);
      let res = await getOverviewGradeList(fd);
      this.gradeList = res.data.result.slice(0, 5);
    },
    //获取班级列表
    async getClassList() {
      let data = {
        fromId: 1000316,
        currPage: 1,
        pageSize: 1000,
        gradeId: this.gradeSelectId,
      };
      let res = await getOverviewClassList(data);
      this.classList = res.data.result.list;
    },
    //年级选择改变班级
    async changeGradeSelectId() {
      //加载这个年级的数据  将班级列表和选择的班级id清空
      this.classSelectId = null;
      this.classList = [];
      //获取班级列表
      await this.getClassList();
    },
    //班级选择改变
    changeClassSelectId() {
      //存在 传年级id与班级id
    },
    //选择开始月份
    changeStartTime() {
      console.log('startTime:', this.startTime);
      if (this.startTime) {
        this.$message({
          message: '请继续选择结束月份',
          type: 'success',
          duration: 2000,
        });
      }
      //把结束月份清掉
      this.endTime = '';
    },
    // 选择结束月份
    async changeEndTime() {
      console.log('endTime', this.endTime);
      if (this.endTime < this.startTime) {
        this.$message({
          message: '结束时间不能小于起始月份',
          type: 'warning',
          duration: 3000,
        });
        this.endTime = '';
        return;
      }
      if (this.endTime === this.startTime) {
        this.$message({
          message: '结束时间不能等于起始月份',
          type: 'warning',
          duration: 3000,
        });
        this.endTime = '';
        return;
      }
      if (!this.startTime) {
        this.$message({
          message: '请先选择起始月份',
          type: 'warning',
          duration: 3000,
        });
        this.endTime = '';
        return;
      }
    },

    //获取学校所有笔
    async getSchoolMac() {
      let data = {
        needStatus: false,
        corpId: 0,
        currPage: 1,
        fromId: 1000316,
        orderDesc: true,
        pageSize: 1500,
      };
      let res = await getSchoolMacList(data);
      if (res.data.code == '0000') {
        this.macList = res.data.result.list.map((item) => item.mac);
        this.usersoce1[0].value = this.macList.length;
        this.getTimeAndPressure();
        //先写死
        // this.usersoce1[1].value = dayjs(12344555556666).format('HH:mm:ss');
        // this.usersoce1[2].value = 285;
      }
    },
    //用mac 获取使用时间 和压力值
    async getTimeAndPressure() {
      let data = {
        penMacs: this.macList,
        beginTime: this.startTime,
        endTime: this.endTime,
        startPageId: 0,
        endPageId: 0,
        startX: 0,
        startY: 0,
        endX: 200,
        endY: 200,
        removeDots: 0,
      };
      try {
        let response = await getDotsTimeAndPressure(data);
        console.log(
          '看选择的时间数据',
          dayjs(this.startTime).format('YYYY-MM-DD HH:mm:ss'),
          dayjs(this.endTime).format('YYYY-MM-DD HH:mm:ss')
        );
        this.usersoce1[1].value = this.secTotime(response.data.total_time);
        this.usersoce1[2].value = Math.floor(response.data.average_force);
        this.loading = false;
      } catch (error) {
        this.$message({
          message: '用mac 获取使用时间和压力值错误',
          type: 'error',
          duration: 3000,
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }

      // setTimeout(() => {

      // }, 2000);

      // dayjs(1318781876406).format('YYYY-MM-DD HH:mm:ss'); // 2011-10-17 00:17:5

      //    {
      //     "total_time": "12344555556666",
      //     "average_force": 285
      // }
    },
    // 将秒转化成时分秒
    secTotime(s) {
      var t = '';
      if (s > -1) {
        var hour = Math.floor(s / 3600);
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (hour < 10) {
          t = '0' + hour + ':';
        } else {
          t = hour + ':';
        }
        if (min < 10) {
          t += '0';
        }
        t += min + ':';
        if (sec < 10) {
          t += '0';
        }
        t += sec.toFixed(0);
      }
      return t;
    },
    //警报颜色
  },
};
</script>

<style scoped="scoped" lang="scss">
::v-deep .el-dialog {
  border-radius: 16px;
}
::v-deep .el-table {
  border-radius: 10px;
  border: 1px solid #dedede;
}
::v-deep .el-dialog__title {
  color: #2d4b87;
}
.onWarningTable {
  margin: 10px;
}
.btns {
  margin: 10px 5px;
  display: flex;
  justify-content: space-around;
  align-content: center;
}
.selectTitle {
  margin-left: 5px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 20px;
  border-left: 6px solid #13264d;
  padding: 0 5px 0 8px;
  margin: 10px 0;
}
.classSelect {
  padding: 5px;
  margin-top: 10px;
}
.UserStudy {
  text-align: left;

  .basebtn {
    height: 40px;
  }
}

.UserStudys {
  text-align: left;
  margin-top: 24px;

  .UserStudys_con {
    margin: 32px 0 24px;
    height: 76vh;
    overflow: auto;

    .UserStudys_con_con {
      margin-top: 24px;
    }

    .UserStudys_con_top_con {
      margin-top: 24px;
    }
  }
}

.text_class {
  height: 80vh;
  overflow: auto;
}
::v-deep .el-table tr {
  background: linear-gradient(to right, #d6f781, #0d88c5);
}
</style>
