import requesthttps from '@/utils/requesthttps'
import request from '@/utils/request'
export function queryClassPlanDetailList(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/class/queryClassPlanDetailList',
        method:'post',
        data
    })
}
export function queryUserBetweenTimeList(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/user/queryUserBetweenTimeList',
        method:'post',
        data
    })
}
export function queryErrorLabelBetweenTimeList(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/user/queryErrorLabelBetweenTimeList',
        method:'post',
        data
    })
}

export function queryUserDailyList(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/user/queryUserDailyList',
        method:'post',
        data
    })
}
export function queryErrorQuestionBetweenTimeList(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/user/queryErrorQuestionBetweenTimeList',
        method:'post',
        data
    })
}
export function getitemsforchapters2(data){
    return request({
        url:'/getitemsforchapters',
        method:'post',
        data
    })
}